
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import DefaultLayout from './layouts/default/default-layout.component';

import HomeRoute from './routes/home/home-route.component';
import ProjectsRoute from './routes/projects/projects-route.component';
import AboutRoute from './routes/about/about-route.component';
import WorkRoute from './routes/work/work-route.component';
import ProjectRoute from './routes/project/project-route.component';

const App: React.FC = () => {

  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<HomeRoute />} />
        <Route path="projects" element={<ProjectsRoute />} />
        <Route path="projects/:id" element={<ProjectRoute />} />
        <Route path="about" element={<AboutRoute />} />
        <Route path="work" element={<WorkRoute />} />
      </Route>
    </Routes>
  );
}

export default App;
