import React from 'react';

import './navigation.styles.scss';

import socialGithub from '../../assets/social-github.svg';
import socialLinkedin from '../../assets/social-linkedin.svg';
import { Link, useLocation } from 'react-router-dom';

interface NavigationProps {
  isActive: boolean;
  onChange: (route: string) => void;
}


const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
  const items = ["home", "about", "projects", "work"];

  const location = useLocation();

  const isActive = (item: string) => {
    if ((item === 'home' && location.pathname === '/') || location.pathname === `/${item}`) {
      return 'is-active';
    }
    return '';
  };

  return (
    <div className={`navigation ${props.isActive ? 'is-active' : ''}`}>
      <div className="navigation-container">
        <ul className="navigation-social">
          <li><img src={socialGithub} alt="Go to Github" /></li>
          <li><img src={socialLinkedin} alt="Go to Github" /></li>
        </ul>
        <ul className="navigation-list">
          {items.map((item) => (
              <li key={item} className={`navigation-item navigation-item-${item}`}>
                <Link to={item === 'home' ? '/' : item} onClick={() => props.onChange(item)}>
                  <button className={`theme-btn ${isActive(item)}`}>
                    {item}
                  </button>
                </Link>
              </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

//const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
//  const items = ["home", "projects", "about", "blog"];
//
//  return (
//    <div className={`navigation ${props.isActive ? 'is-active' : ''}`}>
//      <div className="navigation-container">
//        <ul className="navigation-social">
//          <li><img src={socialGithub} alt="Go to Github" /></li>
//          <li><img src={socialLinkedin} alt="Go to Github" /></li>
//          <li><img src={socialGithub} alt="Go to Github" /></li>
//          <li><img src={socialGithub} alt="Go to Github" /></li>
//          <li><img src={socialGithub} alt="Go to Github" /></li>
//        </ul>
//        <ul className="navigation-list">
//          {items.map((item) => (
//              <li className={`navigation-item navigation-item-${item}`}>
//                <Link to={item === 'home' ? '/' : item} onClick={() => props.onChange(item)}>
//                  <span>{item}</span>
//                  <p>Some text about the item</p>
//                </Link>
//              </li>
//          ))}
//        </ul>
//      </div>
//    </div>
//  );
//};

export default Navigation;
