import React from 'react';
import { Link } from 'react-router-dom';

import './work-route.styles.scss';

const WorkRoute: React.FC = () => {
  return (
    <div className="work-route">
      <div className="work-route-container">
        <div className="work-route-banner">
          <h2>Work</h2>
        </div>

        <div className="work-route-section">
          <h2>MyWorkDoc</h2>
          <ul className="work-route-section-tags">
            <li>Lead Mobile Developer</li>
            <li>Senior Full-Stack Developer</li>
          </ul>
          <p>Hired in as the lead mobile developer, I quickly offered up my web and server-side
              skills to help build out the other applications.   Built native iOS and android
              platforms using Swift, SwiftUI, Kotlin and Kotlin Compose.   Worked on web using
              Angular, with TypeScipt and SASS.   Used NestJS with TypeScript and PostgreSQL.
              Features I worked on include push notifications, and video connections from web
              to mobile.   Also, setup with firebase for analytics and notifications, and keycloak
              for authentication.</p>
          <h4>Projects:</h4>
          <div className="work-route-section-actions">
            <Link to="/projects/7">
              <button>MyWorkDoc Application</button>
            </Link>
          </div>
        </div>

        <div className="work-route-spacer" />

        <div className="work-route-section">
          <h2>HomeTown</h2>
          <p>Started as the lead Android developer, and ended up utilizing skills on iOS.  Built out
              ticketing application for both business and customer sides.   On the customer side,
              users could purchase tickets for events, then see and manage their tickets, using
              QR codes for entrance at the gate.   The business application was used at the gate,
              to sell tickets, scan tickets in, and print receipts - using payment devices,
              printer devices, and the camera.</p>
          <h4>Projects:</h4>
          <div className="work-route-section-actions">
            <Link to="/projects/6">
              <button>HomeTown Fan</button>
            </Link>
            <Link to="/projects/5">
              <button>HomeTown Gate</button>
            </Link>
          </div>
        </div>

        <div className="work-route-spacer" />

        <div className="work-route-section">
          <h2>Kapios</h2>
          <p>A start up in healthcare under ProMedica Hospitals, joining as a frontend developer
              building for mobile and web.   Built tools for hospitals and laboratories to help
              with organization, compliance and day-to-day tasks.   Also, was in involved in one-off
              solutions, whether consumer-facing, or business-facing.</p>
          <h4>Projects:</h4>
          <div className="work-route-section-actions">
            <Link to="/projects/4">
              <button>Face2Face</button>
            </Link>
            <Link to="/projects/3">
              <button>LACS</button>
            </Link>
            <Link to="/projects/2">
              <button>IOSurg</button>
            </Link>
          </div>
        </div>

        <div className="work-route-spacer" />

        <div className="work-route-section">
          <h2>MrDirect, Inc.</h2>
          <p>Joined the company as a web developer to work on their primary e-commerce website for
              the buying and selling of kitchen sinks.  Worked with team members to update website
              design, postings, and manage traffic and sales.</p>
          <h4>Projects:</h4>
          <div className="work-route-section-actions">
            <Link to="/projects/1">
              <button>MrDirect Website</button>
            </Link>
          </div>
        </div>

      </div>
    </div>
  );
};

export default WorkRoute;
