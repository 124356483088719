import React from 'react';
import Loader from '../loader/loader.component';

import './loading.styles.scss';

interface LoadingProps {
  isActive: boolean;
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  return (
    <div className={`loading ${props.isActive ? 'is-active' : ''}`}>
      <div className="loading-container">
        <Loader />
      </div>
    </div>
  );
};

export default Loading;
