import React from 'react';

import './menu.styles.scss';
import logo from '../../assets/logo.svg';
import iconContact from '../../assets/icon-contact.svg';

interface MenuProps {
  isNavActive: boolean;
  onNavChange: (isOpen: boolean) => void;
  onContact: (isOpen: boolean) => void;
}

/**
 * Menu
 *
 * This is the menu for the site, which is _probably_ located in the top right side of the site.
 * This could be considered the 'AppBar'.
 *
 * This will display the logo, and the navigation open/close states.
 */
const Menu: React.FC<MenuProps> = (props: MenuProps) => {
  const [isContacting, setIsContacting] = React.useState(false);

  const toggle = async () => {
    const newValue = !props.isNavActive;
    props.onNavChange(newValue);
    setIsContacting(false);
    props.onContact(false);
  };

  const toggleContact = async () => {
    const newValue = !isContacting;
    setIsContacting(newValue);
    props.onContact(newValue);
    props.onNavChange(false);
  };

  return (
    <div className="menu">
      <div className="menu-left">
        <img className="menu-logo" src={logo} alt="Logo" />
        <button
          className={`menu-button ${props.isNavActive ? 'is-active' : ''}`}
          onClick={toggle}>
          <span>Menu</span>
          <span>Close</span>
        </button>
      </div>
      <div className="menu-right">
        <button className={`menu-button ${isContacting ? 'is-active' : ''}`} onClick={toggleContact}>
          <span>Contact</span>
          <span>Close</span>
        </button>
        <img src={iconContact} alt="Contact" />
      </div>
    </div>
  );
};

export default Menu;
