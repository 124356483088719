import React from 'react';
import { Link } from 'react-router-dom';

import './about-route.styles.scss';

const AboutRoute: React.FC = () => {
  return (
    <div className="about-route">
      <div className="about-route-container">
        <div className="about-route-banner">
          <h2>About</h2>
        </div>

        <div className="about-route-section about-route-fill">
          <h2>Me</h2>
          <p>From the frontend to the servers, and everything in between, I am a full-stack
              developer experienced across all aspects of application development.
              Specializing in frontend programming, I have 20 years experience in websites, and
              10 years in mobile development for native iOS and Android programming.</p>
        </div>

        <div className="about-route-section">
          <h2>Interests</h2>
          <p>I love to build anything and everything - whether it's coding software, creating music, or building woodworking projects.</p>
        </div>

        <div className="about-route-section">
          <h2>Skills</h2>
          <ul>
            <li><strong>iOS:</strong> Swift, SwiftUI, Objective-C, UIKit, App Store</li>
            <li><strong>Android:</strong> Kotlin, Kotlin Compose, Java, Material, Play Store</li>
            <li><strong>Web:</strong> HTML, CSS, JavaScript</li>
            <li><strong>Angular:</strong> TypeScript, Web, Single-Page Application</li>
            <li><strong>React:</strong> TypeScript, Web, Single-Page Application</li>
            <li><strong>Flutter:</strong> Dart, Mobile, Cross-Platform, Material</li>
            <li><strong>Java:</strong> API, REST, ORM, PostgreSQL</li>
            <li><strong>Go:</strong> API, REST, ORM, PostgreSQL</li>
            <li><strong>NestJS:</strong> API, REST, ORM, PostgreSQL</li>
            <li><strong>C++:</strong> API, Websockets, Graphics, Vulkan, Metal</li>
            <li><strong>SQL:</strong> PostgreSQL, MySQL</li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default AboutRoute;
