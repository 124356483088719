import React from 'react';
import { Link } from 'react-router-dom';
import Project from '../../models/project';

import jsonProjects from '../../shared/data-projects.json';

import './projects-route.styles.scss';

const ProjectsRoute: React.FC = () => {

  const data: Project[] = jsonProjects.sort((a, b) => b.id - a.id);

  return (
    <div className="projects-route">
      <div className="projects-route-container">
        <div className="projects-route-banner">
          <h2>Projects</h2>
        </div>


        {data.map((project) => (
          <div key={project.id} className="projects-route-section">
            <h2>{project.title}</h2>
            <p>{project.short}</p>
            <ul className="projects-route-section-tags">
              {project.tags.map((tag) => (
                <li key={tag} className={`tag-${tag.toLowerCase()}`} >{tag}</li>
              ))}
            </ul>
            <Link to={`${project.id}`}>
              <button>Learn More</button>
            </Link>
          </div>
        ))}

      </div>
    </div>
  );
};

export default ProjectsRoute;
