import React from 'react';
import './alert.styles.scss';
import iconX from '../../assets/icon-x.svg';

const Alert: React.FC = () => {
  const [isActive, setIsActive] = React.useState(true);

  const dismiss = () => {
    setIsActive(false);
  };

  return (
    <div className={`alert ${isActive ? 'is-active' : ''}`}>
      <span><strong>ALERT:</strong>&nbsp; This website is in an <em>Alpha</em> state and still under heavy-development and design as of September 2024.&nbsp; Functionality may not work as intended, and some things is expected to change.</span>
      <button onClick={dismiss}><img src={iconX} alt="Close" /></button>
    </div>
  );
};

export default Alert;
