import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import './default-layout.styles.scss';
import Loading from '../../components/loading/loading.component';
import Menu from '../../components/menu/menu.component';
import Navigation from '../../components/navigation/navigation.component';
import Contact from '../../components/contact/contact.component';
import Alert from '../../components/alert/alert.component';

const DefaultLayout: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isNavigating, setIsNavigating] = React.useState(false);
  const [isContacting, setIsContacting] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      console.log("THAT");
    }, 5000);
  });

  return (
    <div className="default-layout">
      <div className={`default-layout-component ${!isNavigating && !isContacting && !isLoading ? 'is-active' : ''}`}>
        <Outlet />
      </div>
      <div className={`default-layout-component ${isContacting ? 'is-active' : ''}`} style={{zIndex: 17}}>
        <Contact />
      </div>
      <div className={`default-layout-component ${isNavigating ? 'is-active' : ''}`} style={{zIndex: 17}}>
        <Navigation
          isActive={isNavigating}
          onChange={(route: string) => {
            setIsNavigating(false);
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              console.log("THIS");
            }, 2000);
          }} />
      </div>
      <div className={`default-layout-menu ${isNavigating ? 'is-active' : ''}`}>
        <Menu
          isNavActive={isNavigating}
          onNavChange={(isOpen) => setIsNavigating(isOpen)}
          onContact={(isOpen) => setIsContacting(isOpen)} />
      </div>
      <div className={`default-layout-alert is-active`}>
        <Alert />
      </div>
      <div className={`default-layout-component ${isLoading ? 'is-active' : ''}`} style={{zIndex: 19}}>
        <Loading isActive={isLoading} />
      </div>
    </div>
  );
};

export default DefaultLayout;
