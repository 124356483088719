import React from 'react';

import './home-route.styles.scss';
import imgMe from '../../assets/nav-home.png';
import { Link } from 'react-router-dom';

const HomeRoute: React.FC = () => {
  return (
    <div className="home-route">
      <div className="home-route-container">

        <div className="home-route-welcome">
          <section>
            <h1>Hello,<br />I'm Eric</h1>
            <span>I am a software developer from Ohio.</span>
          </section>
          <img src={imgMe} alt="Portrait" />
        </div>
        <div className="home-route-spacer" />

        <div className="home-route-section">
          <h2>About Me</h2>
          <p>From the frontend to the servers, and everything in between, I am a full-stack
              developer experienced across all aspects of application development.
              Specializing in frontend programming, I have 20 years experience in websites, and
              10 years in mobile development for native iOS and Android programming.</p>
          <Link to="about">
            <button>More About Me</button>
          </Link>
        </div>

        <div className="home-route-section">
          <h2>Project History</h2>
          <p>Whether it's working on my personal projects, or others, I am passionate about making
              these ideas come to life.   My project history consists of games I have worked on,
              and software I have created and updated, from a ticketing application and e-commerce,
              to health, forms and compliance.</p>
          <Link to="projects">
            <button>My Projects</button>
          </Link>
        </div>

        <div className="home-route-section">
          <h2>Work</h2>
          <p>Every job I hold, I have a deep reverence for, and hope in each opportunity to give
              more than my all to contributing.   Building is in my design, and I always want
              my work to be pixel perfect, whether frontend, backend, or anything else!</p>
          <Link to="work">
            <button>All Work</button>
          </Link>
        </div>

      </div>
    </div>
  );
};

export default HomeRoute;
