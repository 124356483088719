import React from 'react';
import './project-route.styles.scss';
import jsonProjects from '../../shared/data-projects.json';
import Project from '../../models/project';
import { useParams } from 'react-router-dom';

const ProjectRoute: React.FC = () => {
  const params: {id?: string} = useParams();
  const id = Number.parseInt(params?.id ?? '0');
  const project: Project | undefined = jsonProjects.find((data) => data.id === id);
  return (
    <div className="project-route">
      <div className="project-route-container">
        <div className="project-route-section">
          <div className="project-route-content">
            <h2>{project?.title}</h2>
            {project?.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            <ul className="project-route-section-tags">
              {project?.tags.map((tag) => (
                <li key={tag} className={`tag-${tag.toLowerCase()}`} >{tag}</li>
              ))}
            </ul>
          </div>
          <img src={project?.image} alt="Project image" />
        </div>
      </div>
    </div>
  );
};

export default ProjectRoute;
