import React from 'react';

import './contact.styles.scss';

interface ContactInputProps {
  type: string;
  label: string;
}

const ContactInput: React.FC<ContactInputProps> = (props: ContactInputProps) => {
  const isTextArea = props.type === 'textarea';
  return (
    <div className={`contact-input ${isTextArea ? 'contact-input-textarea' : ''}`}>
      {isTextArea ? (
        <textarea></textarea>
      ) : (
        <input type={props.type} />
      )}
      <label>{props.label}</label>
    </div>
  );
};

const Contact: React.FC = () => {
  return (
    <div className="contact">
      <div className="contact-container">
        <form>
          <h2>Contact</h2>
          <span className="warning">This form is currently unavailable!</span>
          <ContactInput type="text" label="Name" />
          <ContactInput type="email" label="Email" />
          <ContactInput type="phone" label="Phone" />
          <ContactInput type="textarea" label="Message" />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
